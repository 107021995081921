<template>
  <el-container>
    <el-aside width="750px">
      <el-container>
        <el-header height="85px">
          <el-form :inline="true">
            <el-form-item label="">
              <el-select v-model="queryParam.SearchField">
                <el-option value="RecordAddTime" label="创建时间"></el-option>
                <el-option value="AddTime" label="调拨时间"></el-option>
              </el-select>
              <el-date-picker v-model="queryParam.dateRange" type="daterange" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy-MM-dd" class="date-range" range-separator="至" :editable="false"
                :clearable="true"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-select v-model="queryParam.Type">
                <el-option value="Out" label="调出仓库"></el-option>
                <el-option value="In" label="调入仓库"></el-option>
              </el-select>
              <el-select v-model="queryParam.TypeValue" placeholder="请选择" style="width:140px;" :clearable="true">
                <el-option v-for="(option, optionIndex) in initData.WarehouseList || []" :key="optionIndex"
                  :label="option.WName" :value="option.WID"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态：">
              <el-select v-model="queryParam.CheckFlag" :clearable="true">
                <el-option value="0" label="待确认"></el-option>
                <el-option value="1" label="待入库"></el-option>
                <el-option value="2" label="已入库"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleQuery">查询</el-button>
              <el-button type="default" @click="reset()">重置</el-button>
            </el-form-item>
            <el-form-item style="float:right;">
              <el-button type="primary" @click="handleAdd" :loading="addLoading">创建调拨单</el-button>
              <el-button type="primary" v-if="checkRight('export_all')" @click="handleExportAll">导出Excel</el-button>
            </el-form-item>
          </el-form>
        </el-header>
        <el-main>
          <grid-table ref="gridTable" :showIndexColumn="false" :showLastEmptyColumn="false" :showPagination="true"
            :tableConfig="tableConfig" @selectionChange="handleSelectionChange" @handleRowClick="handleRowClick"
            :resizable="false">
            <template slot="AllocateCode" slot-scope="{ params }">
              {{ params.row['AllocateCode'] }}
            </template>
            <template slot="StatusText" slot-scope="{ params }">
              <div :class="getStatusColor(params.row['StatusText'])">
                {{ params.row['StatusText'] }}</div>
            </template>
            <template slot="FromWName" slot-scope="{ params }">
              {{ params.row['FromWName'] }}
            </template>
            <template slot="ToWName" slot-scope="{ params }">
              {{ params.row['ToWName'] }}
            </template>
          </grid-table>
        </el-main>
      </el-container>
    </el-aside>
    <el-main>
      <el-container>
        <el-header height="106px">
          <div class="div_edit_row">
            <span class="edit_row_code">调拨单号：
              <template v-if="detail.row.AllocateID <= 0">
                新建
              </template>
              <template v-else>
                {{ detail.row.AllocateCode }}
              </template>
            </span>
            <span class="edit_row_total">调拨合计：{{ detail.Amount }}</span>
            <span class="edit_row_total">货号合计：{{ detail.totalItemNum }}</span>
            <span class="edit_row_total" v-if="detail.row.CheckFlag == 1">实到合计：{{ detail.RealAmount }}</span>
          </div>
          <div class="div_edit_column">
            <div class="edit_row_item">
              <span class="edit_row_item_title">调拨类型：</span>
              <el-select v-model="detail.row.AllocateType" placeholder="请选择" @change="handleUpdated('AllocateType')"
                style="width:190px;" :disabled="detail.row.CheckFlag == 1">
                <el-option v-for="(option, optionIndex) in initData.AllocateTypeList || []" :key="optionIndex"
                  :label="option.label" :value="option.value"></el-option>
              </el-select>
            </div>
            <div class="edit_row_item">
              <span class="edit_row_item_title">调出仓库：</span>
              <el-select v-model="detail.row.FromWID" placeholder="请选择" @change="handleFromWIDUpdated"
                style="width:190px;" :disabled="detail.row.CheckFlag == 1">
                <el-option v-for="(option, optionIndex) in initData.WarehouseList || []" :key="optionIndex"
                  :label="option.WName" :value="option.WID"></el-option>
              </el-select>
            </div>
          </div>
          <div class="div_edit_column">
            <div class="edit_row_item">
              <span class="edit_row_item_title">调拨日期：</span>
              <el-date-picker v-model="detail.row.AddTime" style="width:190px;" type="datetime" @change="handleUpdated()"
                placeholder="选择日期时间" :disabled="detail.row.CheckFlag == 1"></el-date-picker>
            </div>
            <div class="edit_row_item">
              <span class="edit_row_item_title">调入仓库：</span>
              <el-select v-model="detail.row.ToWID" placeholder="请选择" @change="handleUpdated()" style="width:190px;"
                :disabled="detail.row.CheckFlag == 1">
                <el-option v-for="(option, optionIndex) in initData.WarehouseList || []" :key="optionIndex"
                  :label="option.WName" :value="option.WID"></el-option>
              </el-select>
            </div>
          </div>
          <div class="div_edit_column">
            <div class="edit_row_item_note">
              <span class="edit_row_item_note_title">备注：</span>
              <el-input type="textarea" :rows="3" v-model="detail.row.Note" style="width:320px;" resize="none"
                :disabled="detail.row.SignFlag == 2"></el-input>
            </div>
          </div>
        </el-header>
        <el-main>
          <grid-table ref="detailTable" class="detail-table" :showIndexColumn="false" :showCheckboxColumn="false"
            :showPagination="false" :showLastEmptyColumn="false" :tableConfig="detailConfig"
            @handleCheckDisable="handleCheckDisable" @handleInputChange="handleInputChange">
            <template slot="AmountHeader" slot-scope="{}">
              <span style="line-height:32px">调拨数量</span>
              <el-tooltip content="批量设置数量" placement="top-start" v-if="checkRight('allAmount')">
                <el-input-number :controls="false" :min="0" v-model="batchSetNum1" @focus="handleSelectText($event)"
                  @change="handleBatchNum" class="batch-num"></el-input-number>
              </el-tooltip>
            </template>
            <template slot="RealAmountHeader" slot-scope="{}">
              <span>实到数量</span>
              <el-button type="primary" class="table-button" @click="allInStock" v-if="checkRight('allInBtn')"
                size="mini">全到</el-button>
            </template>
            <template slot="Stock" slot-scope="{ params }">
              <div :class="params.row['Stock'] < params.row['Amount'] ? 'red' : 'green'">
                {{ params.row['Stock'] }}</div>
            </template>
            <template slot="Amount" slot-scope="{ params }">
              <el-input-number :controls="false" :disabled="!checkRight('allAmount')" :min="0"
                @change="handleInputChange(params.index, $event, 'Amount')" v-model="params.row.Amount" />
            </template>
            <template slot="RealAmount" slot-scope="{ params }">
              <el-input-number :controls="false" :disabled="!checkRight('allInBtn')" :min="getRealAmountMin(params.row)"
                @change="handleInputChange(params.index, $event, 'RealAmount')" v-model="params.row.RealAmount" />
            </template>
            <template slot="operation" slot-scope="{ params }">
              <i class="el-icon-delete" v-if="detail.row.CheckFlag == 0"
                @click="deleteDetail(params.row, params.index)"></i>
            </template>
          </grid-table>
        </el-main>
        <el-footer height="40px" style="line-height: 40px;">
          <div>
            <el-button type="primary" v-if="checkRight('newDetail')" :disabled="checkRight('newDetailEdit')"
              @click="showAddGoodDlg()">新增商品</el-button>
            <el-button type="danger" v-if="checkRight('delete')" @click="deleteRow"
              :loading="deleteLoading">删除调拨单</el-button>
            <el-button type="success" v-if="checkRight('save')" @click="handleSave(0)"
              :loading="saveLoading">修改保存</el-button>
            <el-button type="primary" v-if="checkRight('wait_sign')" @click="handleSave(1)"
              :loading="waitCheckLoading">运输待入库</el-button>
            <el-button type="primary" v-if="checkRight('sign')" @click="handleSave(2)"
              :loading="checkLoading">确认入库</el-button>
            <el-button type="primary" v-if="checkRight('submit')" @click="handleSave(3)"
              :loading="submitLoading">直接入库</el-button>
            <el-button type="danger" v-if="checkRight('abort')" @click="handleAbort"
              :loading="abortLoading">终止调拨单</el-button>
          </div>
          <div>
            <el-upload ref="uploadXls" accept=".xls,.xlxs"
              :action="xlsData.uploadUrl + '?AllocateID=' + (detail.row == null ? 0 : detail.row.AllocateID)"
              :show-file-list="false" class="div-upload" :headers="xlsData.headers" :on-success="uploadXlsSuccess"
              :multiple="false" :on-error="uploadXlsError" :before-upload="uploadXlsBefore" v-if="checkRight('import')">
              <el-button type="default" plain>导入调拨数量</el-button>
            </el-upload>
            <el-button type="default" v-if="checkRight('export')" @click="handleExport">导出Excel</el-button>
            <el-button type="default" v-if="checkRight('print')" @click="handlePrint">打印调拨单</el-button>
            <el-button type="default" @click="handleCopy">复制报单</el-button>
          </div>
        </el-footer>
      </el-container>
    </el-main>
    <add-goods ref="addGoodsDlg" @handleSave="handleAddGoods"></add-goods>
    <div id="print-view">
      <div class="print-view-main">
        <div class="paper-wrapper paper-a4"></div>
      </div>
      <trade-print ref="tradePrint" :print-view-tab.sync="print.type" :print-config.sync="print.config"></trade-print>
    </div>
  </el-container>
</template>

<script>
import { submit, exportFile } from "@/api/base";
import { baseURL, successCode } from "@/config";
import dayjs from "dayjs";
import clipboard from "@/utils/clipboard";
import AddGoods from "@/views/purchase/components/AddGoods.vue";
import TradePrint from '@/views/stall/trade/components/TradePrint.vue';
export default {
  name: "pageList",
  components: { AddGoods, TradePrint },
  data() {
    return {
      routeParam: {},
      tableConfig: {
        url: "/api/wms/allocate/getPageList",
        rowKey: "AllocateID",
        columns: [
          {
            label: "调拨单号",
            prop: "AllocateCode",
            type: "custom-operation",
            width: "115px",
          },
          {
            label: "创建日期",
            prop: "RecordAddTime",
            type: "text-datetime",
            dateFormat: "yyyy-MM-dd<br/>HH:mm:ss",
            width: "100px",
          },
          {
            label: "状态",
            prop: "StatusText",
            width: "85px",
            type: 'custom-operation',
          },
          {
            label: "调拨类型",
            prop: "AllocateTypeStr",
            width: "90px",
            type: 'text',
          },
          {
            label: "出仓",
            prop: "FromWName",
            type: 'custom-operation',
          },
          {
            label: "入仓",
            prop: "ToWName",
            type: 'custom-operation',
          },
          {
            label: "调拨",
            prop: "TotalAmount",
            type: 'text',
            width: "60px",
          },
          {
            label: "实到",
            prop: "TotalRealAmount",
            type: 'text',
            width: "60px",
          },
        ],
      },
      queryParam: {
        CheckFlag: undefined,
        SearchField: 'RecordAddTime',
        dateRange: [],
        Type: 'Out',
        TypeValue: '',
      },
      initData: {
        WarehouseList: [],
        AllocateTypeList: [],
      },
      detail: {
        loading: false,
        isUpdate: false,
        row: {
          AllocateID: 0,
          AllocateCode: '',
          AllocateType: 1,
          AddTime: undefined,
          FromWID: undefined,
          ToWID: undefined,
          CheckFlag: 0,
          SignFlag: 0,
          Note: '',
        },
        rowDetails: [],
        totalItemNum: 0,
        Amount: 0,
        RealAmount: 0,
      },
      detailConfig: {
        url: "/api/wms/allocate/getDetails",
        columns: [
          {
            label: "货号",
            prop: "ItemNo",
            type: "text",
            filter: true
          },
          {
            label: "颜色",
            prop: "Color",
            type: "text",
            filter: true
          },
          {
            label: "尺码",
            prop: "Size",
            type: "text",
            filter: true
          },
          {
            label: "库存",
            prop: "Stock",
            type: "custom-operation",
            width: "60",
            isHidden: false,
          },
          {
            label: "调拨数量",
            prop: "Amount",
            headerCustom: true,
            type: "custom-operation",
            width: "190",
            align: "center",
            method: 'handleInputChange',
          },
          {
            label: "实到数量",
            prop: "RealAmount",
            headerCustom: true,
            type: "custom-operation",
            width: "190",
            align: "center",
            method: 'handleInputChange',
          },
          {
            label: "操作",
            prop: "operation",
            type: "custom-operation",
            isHidden: false,
            width: "60",
          },
        ],
      },
      batchSetNum1: 0,
      deleteLoading: false,
      addLoading: false,
      selectRows: [],
      saveLoading: false,
      checkLoading: false,
      waitCheckLoading: false,
      submitLoading: false,
      abortLoading: false,
      xlsData: {
        loading: null,
        uploadUrl: `${baseURL}/api/wms/allocate/importAllocateDetail`,
        headers: {
          Authorization: "Bearer " + this.$store.getters["user/accessToken"],
        },
      },
      print: {
        type: 'a4',
        config: {
          a4: {
            "printType": 3,
            "title": "",
            "itemNoWidth": 80,
            "colorWidth": 50,
            "showSettlePrice": false,
            "orderBySkuCountForPrint": true,
            "fontSize": {
              "name": 15,
              "detail": 14,
              "statistics": 15,
              "price": 14
            }
          }
        },
      },
    };
  },
  mounted() {
    var that = this;
    that.handleQuery();
    that.$nextTick(async () => {
      await that.getInitData();
      that.$refs.tradePrint.init();
    });
  },
  methods: {
    getRealAmountMin(row) {
      return this.detail.row.AllocateType == 2 && this.detail.row.CheckFlag == 1 && this.detail.row.SignFlag == 1 ? row.OriginRealAmount : 0
    },
    getStatusColor(status) {
      switch (status) {
        case '待确认': return 'red';
        case '已入库': return 'green';
        case '已终止': return 'gray';
        default: return 'blue';
      }
    },
    deleteDetail(row, index) {
      this.handleInputChange(index, 0, 'Amount');
      this.handleInputChange(index, 0, 'RealAmount');
      this.$refs.detailTable.tableData.splice(index, 1);
      this.detail.rowDetails = this.$refs.detailTable.tableData;
      this.$refs.detailTable.setData(this.$refs.detailTable.tableData);
      this.calcItemNoCount();
    },
    handleInputChange(rowIndex, value, prop) {
      let row = this.$refs.detailTable.tableData[rowIndex];
      if (row[prop] != value) {
        row[prop] = value;
      }
      let changNum = 0;
      if (row[prop] != row['Old' + prop]) {
        changNum = row[prop] - row['Old' + prop];
        row['Old' + prop] = row[prop];
        this.detail.isUpdate = true;
      }
      if (this.detail.row.AllocateType == 1 && prop == 'Amount') {
        row['RealAmount'] = row[prop];
      }
      if (changNum == 0) {
        return;
      }
      this.detail[prop] += changNum;
    },
    allInStock() {
      var that = this;
      that.$refs.detailTable.tableData.forEach((detail, index) => {
        if (detail.RealAmount <= detail.Amount) {
          that.handleInputChange(index, detail.Amount, 'RealAmount');
        }
      });
    },
    handleBatchNum() {
      var that = this;
      var num = that.batchSetNum1;
      if (typeof (num) != 'undefined' && num != '' && num > 0) {
        var list = that.$refs.detailTable.tableData;
        for (let i = 0; i < list.length; i++) {
          list[i]['Amount'] = num;
          if (that.detail.row.AllocateType == 1) {
            list[i]['RealAmount'] = num;
          }
        }
      }
    },
    handleCheckDisable(row, callback) {
      callback(!this.checkRight('save'));
    },
    async getInitData() {
      const { data } = await submit("/api/wms/allocate/getInitData");
      this.initData = data;
    },
    handleUpdated(column) {
      if (column == 'AllocateType') {
        this.$refs.detailTable.setData(this.$refs.detailTable.tableData);
      }
      this.detail.isUpdate = true;
    },
    async handleFromWIDUpdated() {
      var that = this;
      if (typeof (that.detail.row.FromWID) != 'undefined' && that.detail.row.FromWID > 0) {
        var list = [...that.$refs.detailTable.tableData];
        var specIds = [];
        list.forEach(d => {
          if (typeof (d.SpecID) != 'undefined' && d.SpecID > 0) {
            specIds.push(d.SpecID);
          }
        });
        if (specIds.length > 0) {
          that.$refs.detailTable.loading = true;
          const { data } = await submit("/api/wms/allocate/getProductSpecStock", { WID: that.detail.row.FromWID, SpecIDs: specIds });
          list.forEach(d => {
            let stock = 0;
            if (typeof (data[d.SpecID]) != 'undefined') {
              stock = data[d.SpecID];
            }
            d.Stock = stock;
          });
          that.detail.rowDetails = list;
          that.$refs.detailTable.loading = false;
        }
      }
      that.detail.isUpdate = true;
    },
    handleSelectText(e) {
      e.currentTarget.select();
    },
    checkRight(type) {
      var that = this;
      switch (type) {
        case "abort":
          return that.detail.row != null && that.detail.row.AllocateID > 0 && that.detail.row.CheckFlag == 1 && that.detail.row.SignFlag < 2 && that.detail.row.AllocateType == 2;
        case "delete":
          return that.detail.row != null && that.detail.row.AllocateID > 0 && that.detail.row.CheckFlag == 0;//没确认前都可删除
        case "allAmount":
          return (that.detail.row != null && (that.detail.row.CheckFlag == 0 || (that.detail.row.CheckFlag == 1 && that.detail.row.SignFlag < 2))) && that.detail.row.CheckFlag != 1;
        case "allInBtn":
          return that.detail.row != null && (that.detail.row.CheckFlag == 1 && that.detail.row.SignFlag < 2);
        case "save":
        case "newDetail":
          return that.detail.row == null || (that.detail.row != null && that.detail.row.CheckFlag == 0);
        case "newDetailEdit":
          return !(that.detail.row != null && typeof (that.detail.row.FromWID) != 'undefined' && that.detail.row.FromWID > 0);
        case "sign":
          return that.detail.row != null && that.detail.row.CheckFlag == 1 && that.detail.row.SignFlag < 2;
        case "submit":
          return that.detail.row != null && that.detail.row.CheckFlag == 0 && that.detail.row.AllocateType < 2;
        case "wait_sign":
          return that.detail.row != null && that.detail.row.AllocateType == 2 && that.detail.row.SignFlag < 2 && that.detail.row.CheckFlag == 0;
        case "set_wait_sign_num":
          return that.detail.row != null && that.detail.row.CheckFlag == 1 && that.detail.row.SignFlag < 2;
        case "print":
        case "export":
          return that.detail.row != null && that.detail.row.AllocateID > 0;
        case "export_all":
          return true;
        case "import":
          return that.detail.row != null && that.detail.row.AllocateID > 0 && that.detail.row.CheckFlag == 0;
      }
    },
    handleAdd() {
      var that = this;
      if (that.detail.isUpdate) {
        that.$confirm(`当前调拨单存在变动尚未保存，是否继续创建新的调拨单?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          that.removeEditData(true);
        }).catch(() => {
          //取消          
        });
      } else {
        that.removeEditData(true);
      }
    },
    handleAbort() {
      var that = this;
      if (that.detail.row == null) {
        return;
      }
      that.$confirm(`您是否要终止当前调拨单?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        that.abortLoading = true;
        const result = await submit(`/api/wms/allocate/abort`, { AllocateIDs: that.detail.row.AllocateID });
        that.abortLoading = false;
        if (result.success) {
          that.$message({
            type: "success",
            message: "删除成功!",
          });
          that.removeEditData();
          that.handleQuery();
        }
      }).catch(() => {
        //取消          
      });
    },
    deleteRow() {
      var that = this;
      if (that.detail.row == null) {
        return;
      }
      that.$confirm(`您是否要删除当前调拨单?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        that.deleteLoading = true;
        const result = await submit(`/api/wms/allocate/delete`, { AllocateIDs: that.detail.row.AllocateID });
        that.deleteLoading = false;
        if (result.success) {
          that.$message({
            type: "success",
            message: "删除成功!",
          });
          that.removeEditData();
          that.handleQuery();
        }
      }).catch(() => {
        //取消          
      });
    },
    reset() {
      this.queryParam.dateRange = [];
      this.queryParam.CheckFlag = undefined;
      this.queryParam.TypeValue = undefined;
      this.handleQuery();
    },
    showAddGoodDlg() {
      var that = this;
      that.$refs.addGoodsDlg.init(true, '新增调拨商品', undefined, that.detail.row.FromWID);
    },
    handleAddGoods(data) {
      var that = this;
      var list = [...that.$refs.detailTable.tableData];
      for (let color in data.Colors) {
        for (let i = 0; i < data.Colors[color].Details.length; i++) {
          if (!list.some(t => t.SpecID == data.Colors[color].Details[i].SpecID)) {
            list.push({
              ADetailID: 0,
              AllocateID: that.detail.row.AllocateID,
              ProductID: data.Colors[color].Details[i].ProductID,
              ItemNo: data.ItemNo,
              SpecID: data.Colors[color].Details[i].SpecID,
              SpecName: color + '-' + data.Colors[color].Details[i].Size,
              Color: color,
              Size: data.Colors[color].Details[i].Size,
              Stock: data.Colors[color].Details[i].Stock,
              Amount: 0,
              RealAmount: 0,
            });
          }
        }
      }
      this.detail.rowDetails = list;
      this.$refs.detailTable.setData(list);
      this.calcItemNoCount();
      this.detail.isUpdate = true;
    },
    removeEditData(newTime) {
      this.detail.row = {
        AllocateID: 0,
        AllocateCode: '',
        AllocateType: 1,
        AddTime: typeof (newTime) != 'undefined' && newTime ? dayjs().format('YYYY-MM-DD HH:mm:ss') : undefined,
        FromWID: undefined,
        ToWID: undefined,
        CheckFlag: 0,
        SignFlag: 0,
        Note: '',
      };
      this.detailConfig.columns[3].isHidden = false;
      this.detailConfig.columns[this.detailConfig.columns.length - 1].isHidden = false;
      this.detail.rowDetails = [];
      var isEditNum = this.checkRight('save');
      var isComplete = this.detail.row.SignFlag == 2;
      var notDelete = (this.detail.row.SignFlag != 0 || this.detail.row.CheckFlag != 0);
      this.$refs.detailTable.setData([], isEditNum, isComplete, notDelete, this.detail.row);
      this.detail.isUpdate = false;
      this.detail.totalItemNum = 0;
      this.detail.Amount = 0;
      this.detail.RealAmount = 0;
      this.batchSetNum1 = 0;
    },
    handleQuery(id = 0, isCurrentChange = false) {
      var that = this;
      that.$refs.gridTable.searchParam = { ...that.queryParam };
      if (that.queryParam.dateRange && that.queryParam.dateRange.length > 0) {
        that.$refs.gridTable.searchParam.TimeStart = that.queryParam.dateRange[0];
        that.$refs.gridTable.searchParam.TimeEnd = that.queryParam.dateRange[1];
      } else {
        that.$refs.gridTable.searchParam.TimeStart = '';
        that.$refs.gridTable.searchParam.TimeEnd = '';
      }
      if (that.$refs.gridTable.searchParam.Type == 'Out') {
        that.$refs.gridTable.searchParam.FromWID = that.$refs.gridTable.searchParam.TypeValue;
      } else {
        that.$refs.gridTable.searchParam.ToWID = that.$refs.gridTable.searchParam.TypeValue;
      }
      delete that.$refs.gridTable.searchParam.Type;
      delete that.$refs.gridTable.searchParam.TypeValue;
      delete that.$refs.gridTable.searchParam.dateRange;
      if (id > 0) {
        that.$refs.gridTable.fetchData(isCurrentChange, function (tableData) {
          var rows = tableData.filter(x => x.AllocateID == id);
          if (rows.length > 0) {
            that.$nextTick(() => {
              that.rowClick(rows[0]);
            });
          }
        });
      } else {
        that.$refs.gridTable.fetchData();
        that.removeEditData();
      }
    },
    handleSelectionChange(selection) {
      this.selectRows = selection;
      if (selection.length <= 0) {
        this.removeEditData();
      }
    },
    handleRowClick(row) {
      var that = this;
      if (that.detail.isUpdate) {
        that.$confirm(`当前盘点单存在变动尚未保存，点击确定将丢失变动过的数据，是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          that.rowClick(row);
        }).catch(() => {
          //取消          
        });
      } else {
        that.rowClick(row);
      }
    },
    async rowClick(row) {
      var that = this;
      that.$refs.gridTable.$refs.gridTable.clearSelection();
      that.$refs.gridTable.$refs.gridTable.toggleRowSelection(row);
      that.$refs.detailTable.loading = true;

      that.detailConfig.columns[3].isHidden = false;
      that.detailConfig.columns[that.detailConfig.columns.length - 1].isHidden = false;
      if (row.SignFlag == 2) {
        that.detailConfig.columns[3].isHidden = true;
        that.detailConfig.columns[that.detailConfig.columns.length - 1].isHidden = true;
      }
      if (row.CheckFlag == 1 && row.AllocateType == 2) {
        that.detailConfig.columns[3].isHidden = true;
        that.detailConfig.columns[that.detailConfig.columns.length - 1].isHidden = true;
      }
      that.detail.row = JSON.parse(JSON.stringify(row));
      that.detail.row.Details = [];
      const result = await submit("/api/wms/allocate/getDetails", { AllocateID: row.AllocateID });
      that.detail.row = result.data.main;
      that.detail.rowDetails = result.data.details;
      that.detail.Amount = result.data.main.TotalAmount;
      that.detail.RealAmount = result.data.main.TotalRealAmount;
      that.calcItemNoCount();
      that.$refs.detailTable.setData(result.data.details);
      that.detail.isUpdate = false;
      that.$refs.detailTable.loading = false;
      if (that.detail.row.CheckFlag == 1 && that.detail.row.SignFlag == 1) {
        that.$nextTick(() => {
          if (!that.detail.rowDetails.some(x => x.RealAmount < x.Amount)) {
            that.$confirm("当前实到数量都大于调拨数量是否完成调拨单？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(async () => {
              await that.handleComplete();
            }).catch(() => {
              //取消          
            });
          }
        });
      }
    },
    calcItemNoCount() {
      let itemNos = {};
      for (let i = 0; i < this.detail.rowDetails.length; i++) {
        let x = this.detail.rowDetails[i];
        itemNos[x['ItemNo']] = 1;
      }
      this.detail.totalItemNum = Object.keys(itemNos).length;
    },
    async handleComplete() {
      var param = { ...this.detail.row };
      const result = await submit(`/api/wms/allocate/complete`, param);
      if (result.success) {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.handleQuery(result.data, param.AllocateID > 0);
      }
    },
    async handleSave(type) {
      var that = this;
      //验证数据
      var param = { ...that.detail.row };
      if (typeof (param.AllocateType) == 'undefined' || param.AllocateType <= 0) {
        this.$baseMessage(`请选择调拨类型`, "error");
        return;
      }
      if (typeof (param.FromWID) == 'undefined' || param.FromWID <= 0) {
        this.$baseMessage(`请选择调出仓库`, "error");
        return;
      }
      if (typeof (param.ToWID) == 'undefined' || param.ToWID <= 0) {
        this.$baseMessage(`请选择调入仓库`, "error");
        return;
      }
      param.DetailList = [];
      var errorList = [];
      var list = [...that.$refs.detailTable.tableData];
      var hasInstock = list.some(x => (x.RealAmount - x.OriginRealAmount) > 0);
      var realAmountError = [];
      list.forEach(y => {
        let tempY = { ...y };
        if (that.detail.row.CheckFlag == 0 && (y.Amount == undefined || y.Amount <= 0)) {
          errorList.push('货号[' + y.ItemNo + ']规格[' + y.SpecName + ']调拨数量不能为空');
        }
        if (that.detail.row.CheckFlag == 1 && that.detail.row.SignFlag < 2 && !hasInstock && (y.RealAmount == undefined || y.RealAmount <= 0)) {
          realAmountError.push('货号[' + y.ItemNo + ']规格[' + y.SpecName + ']调拨实到数量必须大于0');
        }
        if (that.detail.row.CheckFlag == 0 && y.Amount > y.Stock) {
          errorList.push('货号[' + y.ItemNo + ']规格[' + y.SpecName + ']库存不足，无法调拨');
        }
        param.DetailList.push(tempY);
      });
      if (param.DetailList.length <= 0) {
        this.$baseMessage(`请选择要调拨商品`, "error");
        return;
      }
      if (errorList.length > 0) {
        this.$baseMessage(errorList.join('<br/>'), "error");
        return;
      }
      if (realAmountError.length > 0) {
        this.$baseMessage(`实到数量未变化，无法入库`, "error");
        return;
      }
      param.Type = type;
      var confirmText = '';
      if (type == 2 || type == 3) {
        confirmText = '确认提交当前调拨单？';
      }
      if (confirmText != '') {
        that.$confirm(confirmText, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          await that.handleSubmit(param);
        }).catch(() => {
          //取消          
        });
      } else {
        await that.handleSubmit(param);
      }
    },
    async handleSubmit(param) {
      var that = this;
      switch (param.Type) {
        case 0: that.saveLoading = true; break;
        case 1: that.waitCheckLoading = true; break;
        case 2: that.checkLoading = true; break;
        case 3: that.submitLoading = true; break;
      }
      const result = await submit(`/api/wms/allocate/edit`, param).catch((error) => {
        switch (param.Type) {
          case 0: that.saveLoading = false; break;
          case 1: that.waitCheckLoading = false; break;
          case 2: that.checkLoading = false; break;
          case 3: that.submitLoading = false; break;
        }
      });
      switch (param.Type) {
        case 0: that.saveLoading = false; break;
        case 1: that.waitCheckLoading = false; break;
        case 2: that.checkLoading = false; break;
        case 3: that.submitLoading = false; break;
      }
      if (result.success) {
        that.$message({
          type: "success",
          message: "操作成功!",
        });
        that.handleQuery(result.data, param.AllocateID > 0);
      }
    },
    async handleExportAll() {
      var that = this;
      const today = dayjs().format('YYYYMMDDHHmmss');
      var excelName = '调拨单_' + today + '.xls';
      await that.baseExport(excelName, '/api/wms/allocate/export', that.$refs.gridTable.searchParam);
    },
    async handleExport() {
      var that = this;
      const today = dayjs(that.detail.row.RecordAddTime).format('YYYYMMDDHHmmss');
      var status = (that.detail.row.SignFlag == 2 ? '已入库' : (that.detail.row.CheckFlag == 1 ? '待入库' : '待确认'));
      var excelName = '【' + status + '】' + '调拨单_' + '_' + today + '.xls';
      await that.baseExport(excelName, '/api/wms/allocate/export', {
        AllocateID: that.detail.row.AllocateID,
      });
    },
    async baseExport(excelName, url, param) {
      const { data } = await exportFile(url, param);
      const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = excelName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    uploadXlsSuccess(res) {
      if (successCode.includes(res.code)) {
        this.$message({ type: "success", message: res.msg });
      } else {
        this.$message({ type: "error", message: "导入错误：" + res.msg });
      }
      this.$refs.uploadXls.clearFiles();
      this.handleQuery(res.data, true);
      if (this.xlsData.loading != null) {
        this.xlsData.loading.close();
      }
    },
    uploadXlsError() {
      this.$message({ type: "error", message: "导入失败" });
      if (this.xlsData.loading != null) {
        this.xlsData.loading.close();
      }
    },
    uploadXlsBefore(file) {
      this.xlsData.loading = this.$loading({
        lock: true,
        text: "正在导入中......"
      });
    },
    async handlePrint() {
      var that = this;
      var allocateID = that.detail.row.AllocateID;
      await that.$refs.tradePrint.getAllocatePrintInfo(allocateID);
      if (that.$refs.tradePrint.printData.length > 0) {
        await this.$refs.tradePrint.printAllocate();
        this.$('#print-view .paper-bill,#print-view .paper-tag').empty();
      }
    },
    handleCopy(e) {
      var that = this;
      var list = [...that.$refs.detailTable.tableData];
      if (list.length <= 0) {
        that.$baseMessage(`没有可复制的商品`, "error");
        return;
      }
      var arr = {};
      list.forEach(x => {
        if (typeof (arr[x.ItemNo]) == 'undefined') {
          arr[x.ItemNo] = [];
        }
        var colorItem = '';
        var num = that.detail.CheckFlag == 1 ? x.RealAmount : x.Amount;
        if (num > 0) {
          colorItem += ' ' + x.Size + '*' + num;
        }
        if (colorItem != '') {
          arr[x.ItemNo].push(x.Color + colorItem);
        }
      });
      var text = '';
      for (var key in arr) {
        if (arr[key].length > 0) {
          text += key + '' + arr[key].join('') + '\r\n';
        }
      }
      if (text == '') {
        that.$baseMessage(`没有可复制的商品，请商品数量大于0`, "error");
      } else {
        clipboard(text, e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {

  .el-input-number--small {
    width: inherit !important;
  }

  .table_btn_right {
    position: absolute;
    right: 3px;
  }

  .el-button--mini {
    padding: 7px 5px !important;
  }

  .el-table .cell.el-tooltip {
    white-space: break-spaces !important;
  }

  .green {
    color: green;
  }

  .gray {
    color: gray;
  }

  .red {
    color: red;
  }

  .blue {
    color: blue;
  }

  .div_edit_column {
    float: left;
    width: 280px;

    .edit_row_item {
      width: 280px;
      margin-left: 10px;
      font-size: 15px;
      margin-top: 5px;
    }

    .edit_row_item_title {
      width: 78px;
      text-align: right;
      display: inline-block;
    }

    .edit_row_item_note_title {
      width: 48px;
      text-align: right;
      vertical-align: top;
      display: inline-block;
    }

    .edit_row_item_note {
      margin-left: 10px;
      font-size: 15px;
      margin-top: 5px;
    }
  }

  .div_edit_column:last-child {
    width: inherit !important;
  }

  .div_edit_row {
    margin-top: 5px;


    .edit_row_code {
      font-weight: bold;
      font-size: 20px;
    }

    .edit_row_total {
      margin-left: 15px;
      font-size: 16px;
    }

  }

  .div_edit_row:first-child {
    margin-top: 0px;
  }

  .pointed {
    color: #409EFF !important;
    margin: 0 5px;
    font-size: 16px;
  }


  .el-aside {
    .el-select {
      width: 100px;
      margin-right: 5px;
    }

    .date-range {
      width: 210px;

      ::v-deep {
        .el-range-input {
          width: 70px;
        }
      }
    }

    .el-radio {
      margin-right: 10px;
    }

    .el-form-item__label {
      padding-right: 0;
    }
  }

  .el-main {
    .el-header {

      .btn-set-num {
        float: right;
        margin-right: 225px;
      }
    }

    .el-main {

      .size-tag {
        height: 36px;
        line-height: 33px;
        margin: 6px 5px 3px 0px;
        float: left;

        .el-input--small .el-input__inner {
          height: 22px;
          line-height: 22px;
          padding: 0px;
          text-align: center;
        }
      }

      .tag-item:hover .icon-delete {
        display: block;
      }

      .span-delete {
        color: red;
        font-size: 12px;
        cursor: pointer;
        margin-left: 5px;
      }

      .span-copy {
        color: green;
        font-size: 12px;
        cursor: pointer;
        margin-left: 5px;
      }

      .span-delete:hover {
        text-decoration: underline;
      }

      .icon-delete {
        background-color: red;
        border-radius: 10px;
        color: #fff;
        opacity: 0.8;
        float: left;
        cursor: pointer;
        display: none;
        margin-left: -15px;
      }
    }

    .detail-table {

      .el-icon-delete {
        color: #ff0000;
        cursor: pointer;
      }

      td .el-input-number {
        .el-input {
          width: 150px;
          font-size: 20px;

          .el-input__inner {
            text-align: center;
          }
        }
      }

      .batch-num {
        float: right;
        width: 60px !important;
      }

      .table-button {
        float: right;
        width: 60px !important;
        padding: 5px !important;

        .el-input__inner {
          padding: 0 !important;
        }
      }
    }

    .el-footer {
      vertical-align: middle;

      .el-button {
        height: 30px;
      }

      &>div {
        display: inline-block;
        width: 50%;

        &:last-child {
          text-align: right;
          width: 50%;

          .div-upload,
          .el-button {
            display: inline-block;
            margin-left: 5px;
          }
        }
      }
    }
  }
}


#print-view {
  position: fixed;
  left: -1000px;
}
</style>
